/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Badge, Box, Button, Center, Flex, Table, Tbody, Td, Tfoot, Th, Thead, Tooltip, Tr, Text, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CompanyName from '../../CompanyName';
import Loading from '../../Loading';
import LocalizedDate from '../../LocalizedDate';
import NoData from '../../NoData';
import CompanyBadge from '../../Other/CompanyBadge';
import c from '../../../constant';
import useStore from '../../../store';
import AddCompanyPartnerModal from '../../Modal/AddCompanyPartnerModal';

const Q_GET = gql`
	query GET($companyId: String) {
		getCompanyAgentPartners(companyId: $companyId) {
			id
			model
			companyType
			company {
				id
				name
				nameKhmer
				type
				logo
			}
			intermediary {
				id
				name
				nameKhmer
				type
				logo
			}
			createdAt
			active
			approvalId
			active
			inactiveAt
			inactiveById
		}
	}
`;

function DistributionSystemPartner({ companyId = null, companyType = null, onDataChanged = () => null, ...props }) {
	const { push } = useHistory();
	const { t } = useTranslation();
	const [loadData, { loading, data }] = useLazyQuery(Q_GET);
	const { currentUser } = useStore((state) => state.currentUser);
	const { isOpen: isInserting, onOpen: openInserting, onClose: closeInserting } = useDisclosure();

	useEffect(() => {
		companyId && loadData({ variables: { companyId } });
	}, [companyId]);

	useEffect(() => {
		onDataChanged(data?.getCompanyAgentPartners);
	}, [data]);

	function Status({ status = null }) {
		return (
			<>
				<>{status === 'active' && <Badge colorScheme="green">{t('Active')}</Badge>}</>
				<>{status === 'inactive' && <Badge colorScheme="red">{t('Inactive')}</Badge>}</>
			</>
		);
	}

	function Model({ model = null, color = 'orange' }) {
		switch (model) {
			case 'direct':
				return (
					<Badge variant="solid" colorScheme={color}>
						{t('Direct Sale')}
					</Badge>
				);
			case 'ref':
				return (
					<Badge variant="solid" colorScheme={color}>
						{t('Referral')}
					</Badge>
				);
			default:
				return null;
		}
	}

	function isAllowedIntermediaryCompany(type) {
		return type === c.insuranceCompanyType.agent.value;
	}

	function isAllowedInsuranceCompany(type) {
		return type === c.insuranceCompanyType.general.value || type === c.insuranceCompanyType.life.value || type === c.insuranceCompanyType.micro.value;
	}

	return (
		<Flex {...props}>
			<Box className="hidden-print" width="100%">
				<Center>
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Flex p={4} pt={2} pb={2} mb={4} h="48px" bg="#FAFAFA" alignItems="center" justifyContent="end" borderBottom="1px solid #dbdbdb">
							<Flex alignItems="center">
							{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyLicensing) && (
									<Flex
										userSelect="none"
										onClick={openInserting}
										cursor="pointer"
										alignItems="center"
										justifyContent="center"
										w="32px"
										h="32px"
										borderRadius={c.borderRadius}
										border="1px solid #dbdbdb"
										boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
									>
										<Text fontSize="26px" mt="2px" fontWeight="500">
											+
										</Text>
									</Flex>
								)}
							</Flex>
						</Flex>
						<Box p="16px">
							{loading && (
								<>
									<Loading />
								</>
							)}
							{!loading && data?.getCompanyAgentPartners?.length <= 0 && (
								<>
									<NoData />
								</>
							)}
							{!loading && data?.getCompanyAgentPartners?.length > 0 && (
								<>
									<Table variant="simple">
										<Thead>
											<Tr>
												{isAllowedIntermediaryCompany(companyType) && <Th>{t('Insurance Company Name')}</Th>}
												{isAllowedIntermediaryCompany(companyType) && <Th>{t('Insurance Company Type')}</Th>}
												{isAllowedInsuranceCompany(companyType) && <Th>{t('Intermediary Company Name')}</Th>}
												{isAllowedInsuranceCompany(companyType) && <Th>{t('Intermediary Company Type')}</Th>}
												<Th>{t('Model')}</Th>
												<Th>{t('Date')}</Th>
												<Th>{t('View Detail')}</Th>
												<Th isNumeric>{t('Status')}</Th>
											</Tr>
										</Thead>
										<Tbody>
											{data?.getCompanyAgentPartners?.map((item, index) => {
												return (
													<Tr key={`item-${index}`} bg="white">
														{isAllowedIntermediaryCompany(companyType) && (
															<Td>
																<CompanyName company={item?.company} />
															</Td>
														)}
														{isAllowedIntermediaryCompany(companyType) && (
															<Td>
																<CompanyBadge type={item?.company?.type} />
															</Td>
														)}
														{isAllowedInsuranceCompany(companyType) && (
															<Td>
																<CompanyName company={item?.intermediary} />
															</Td>
														)}
														{isAllowedInsuranceCompany(companyType) && (
															<Td>
																<CompanyBadge type={item?.intermediary?.type} />
															</Td>
														)}
														<Td>
															<Model model={item?.model} />
														</Td>
														<Td>
															<LocalizedDate dateString={item?.createdAt} />
														</Td>
														<Td>
															{item?.approvalId?<Button onMouseDown={() => push(`/intermediary/partner-request/detail?id=${item?.approvalId}`)}>
																{t('View Detail')}
															</Button>:'-'}
														</Td>
														<Td isNumeric>
															{item?.active ? (
																<>
																	<Status status="active" />
																</>
															) : (
																<Tooltip label={<LocalizedDate dateString={item?.inactiveAt} color="white" />}>
																	<Box
																		onMouseDown={() =>
																			push(`/intermediary/partner-request/detail?id=${item?.inactiveById}`)
																		}
																	>
																		<Status status="inactive" />
																	</Box>
																</Tooltip>
															)}
														</Td>
													</Tr>
												);
											})}
										</Tbody>
										<Tfoot>
											<Tr>
												{isAllowedIntermediaryCompany(companyType) && <Th>{t('Insurance Company Name')}</Th>}
												{isAllowedIntermediaryCompany(companyType) && <Th>{t('Insurance Company Type')}</Th>}
												{isAllowedInsuranceCompany(companyType) && <Th>{t('Intermediary Company Name')}</Th>}
												{isAllowedInsuranceCompany(companyType) && <Th>{t('Intermediary Company Type')}</Th>}
												<Th>{t('Model')}</Th>
												<Th>{t('Date')}</Th>
												<Th>{t('View Detail')}</Th>
												<Th isNumeric>{t('Status')}</Th>
											</Tr>
										</Tfoot>
									</Table>
								</>
							)}
						</Box>
					</Box>
				</Center>
			</Box>

			<AddCompanyPartnerModal
				companyId={companyId}
				isOpen={isInserting}
				onClose={closeInserting}
				partneredList={data?.getCompanyAgentPartners}
				callback={(e) => {
					loadData({ variables: { companyId } })
				}}
			/>
		</Flex>
	);
}

export default DistributionSystemPartner;

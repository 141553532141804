/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text, Center, Tabs, TabList, TabPanels, Tab, TabPanel, Button, SimpleGrid, Divider, useToast } from '@chakra-ui/react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { useBranchRequestStore } from '../../../../components/ManageAgentPartner/Miscellaneous/IntermediaryStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import PrintLayout from '../../../../components/Print/PrintLayout';
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent/BreadcrumbComponent';
import DownloadAndViewButton from '../../../../components/Files/DownloadAndViewButton';
import ProfessionalIndividualList from '../components/ProfessionalIndividualList';
import UpdateIntemediaryBranchStatus from '../components/UpdateIntemediaryBranchStatus';
import Loading from '../../../../components/Loading';
import NoData from '../../../../components/NoData';
import LabelDetail from '../../../../components/LabelDetail';
import CompanyName from '../../../../components/CompanyName';
import LocalizedDate from '../../../../components/LocalizedDate';
import IntermediaryStatus from '../../../../components/ManageAgentPartner/Miscellaneous/IntermediaryStatus';
import IntermediaryModel from '../../../../components/ManageAgentPartner/Miscellaneous/IntermediaryModel';
import Address from '../../../../components/Address';
import SaleProfessionalList from './components/SaleProfessionalList';
import useStore from '../../../../store';
import useMoment from '../../../../hooks/useMoment';
import c from '../../../../constant';

const LABEL_WIDTH = '200px';

const Q_GET_REQUESTS = gql`
	query get($id: String!) {
		getIntermediaryRequestForBranchById(id: $id) {
			id
			number
			branchStatus
			description
			status
			adminStatus
			createdAt
			createdBy
			type
			branchName
			branchNameEn
			nbcLetter
			provinceObject {
				id
				code
				name
				latinName
				administrativeUnit
				administrativeUnitLatin
				administrativeUnitEn
			}
			districtObject {
				id
				code
				name
				latinName
				administrativeUnit
				administrativeUnitLatin
				administrativeUnitEn
			}
			communeObject {
				id
				code
				name
				latinName
				administrativeUnit
				administrativeUnitLatin
				administrativeUnitEn
			}
			villageObject {
				id
				code
				name
				latinName
				administrativeUnit
				administrativeUnitLatin
				administrativeUnitEn
			}
			isGeoManual
			geoManual
			streetNumber
			houseNumber
			managerName
			managerNameEn
			gender
			idDocumentType
			idDocumentNumber
			idDocument
			workingContract
			completedLetter
			details {
				id
				requestId
				partnerId
				partnerType
				model
				partner {
					id
					name
					nameKhmer
					type
					logo
				}
			}
			company {
				name
				nameKhmer
				type
				logo
			}
		}
	}
`;

const M_NEXT = gql`
	mutation next($id: String!, $requestDate: String, $solarDate: String, $lunarDate: String) {
		nextStepIntermediaryBranchReview(id: $id, requestDate: $requestDate, solarDate: $solarDate, lunarDate: $lunarDate)
	}
`;

const direct = 'direct';
const reference = 'ref';

function IntermediaryPartnerRequest() {
	const toast = useToast();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') ? urlParams.get('id') : null;
	const setPermitted = useBranchRequestStore((state) => state.setPermitted);
	const { t } = useTranslation();
	const { paperGeneralDateString, paperSolarDateString, paperLunarDateString } = useMoment();
	const { currentUser: user } = useStore((state) => state.currentUser);
	const { isPermitted } = useBranchRequestStore((state) => state.isPermitted);
	const [data, setData] = useState(null);
	const [pfListCompleted, setPfListCompleted] = useState({});
	const [isFormCompleted, setIsFormCompleted] = useState(false);
	const [loadData, { loading, data: loadedData, error: loadedError }] = useLazyQuery(Q_GET_REQUESTS);
	const [submitToNextStep, { error: errorNext, loading: loadingNext, data: dataNext }] = useMutation(M_NEXT);

	useEffect(() => {
		loadData({ variables: { id } });
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (loadedData) {
			setData(loadedData?.getIntermediaryRequestForBranchById);
		}
	}, [loadedData]);

	useEffect(() => {
		if (dataNext?.nextStepIntermediaryBranchReview) {
			refresh();
		}
	}, [dataNext]);

	useEffect(() => {
		if (loadedError) {
			if (loadedError?.graphQLErrors?.length > 0) {
				loadedError?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(loadedError?.message ? loadedError?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [loadedError]);

	useEffect(() => {
		if (errorNext) {
			if (errorNext?.graphQLErrors?.length > 0) {
				errorNext?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(errorNext?.message ? errorNext?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [errorNext]);

	useEffect(() => {
		let completed = true;
		if (data?.branchStatus === 'rejected') {
			completed = true;
		} else if (data?.branchStatus === 'approved') {
			completed = isPfListCompleted(pfListCompleted);
		} else {
			completed = false;
		}
		setIsFormCompleted(completed);
	}, [pfListCompleted, data]);

	useEffect(() => {
		if (data?.adminStatus && user?.position) {
			setPermitted(data?.adminStatus, user?.position);
		}
	}, [data, user]);

	function isPfListCompleted(data) {
		let completed = true;
		Object.keys(pfListCompleted).forEach((key) => {
			if (pfListCompleted[key] === false) {
				completed = false;
			}
		});
		return completed;
	}

	function refresh() {
		setData(null);
		setTimeout(() => loadData({ variables: { id } }), 100);
	}

	return (
		<>
			<PrintLayout>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'Manage Agent Partner',
								path: '/manage-agent-partner',
							},
							{
								name: 'Request for New Branch',
								path: '/intermediary/branch-request?page=1',
							},
							{
								name: 'Detail',
								path: '#',
							},
						]}
					/>
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="16px"
						>
							<Flex
								alignItems="center"
								justifyContent="space-between"
								bg="#FAFAFA"
								p="16px"
								pt="8px"
								pb="8px"
								mb="16px"
								borderBottom="1px solid #dbdbdb"
							>
								<Flex w="100%" justifyContent="space-between" alignItems="center">
									<Text fontSize="x-large" fontWeight="bold" mr="4">
										{t('Detail')}
									</Text>
									{data?.id && (
										<>
											{data?.adminStatus !== 'completed' && (
												<>
													{isPermitted && (
														<>
															<Button
																isLoading={loadingNext}
																isDisabled={!isFormCompleted}
																rightIcon={
																	<FontAwesomeIcon
																		icon={data?.adminStatus === 'reviewing_by_department' ? faCheck : faArrowRight}
																		style={{ fontSize: 16 }}
																	/>
																}
																colorScheme="blue"
																variant="solid"
																size="sm"
																borderRadius={c.borderRadius}
																border="1px solid #bdc3c7"
																ml={2}
																onMouseDown={() => {
																	if (isFormCompleted) {
																		submitToNextStep({
																			variables: {
																				id: data?.id,
																				requestDate: paperGeneralDateString(data?.createdAt, 'ឆ្នាំ'),
																				solarDate: paperSolarDateString(),
																				lunarDate: paperLunarDateString(),
																			},
																		});
																	}
																}}
															>
																{t(data?.adminStatus === 'reviewing_by_department' ? 'Done' : 'Submit')}
															</Button>
														</>
													)}
												</>
											)}
										</>
									)}
								</Flex>
							</Flex>
							<Box p="16px" pt="8px" pb="8px" mb="16px">
								{loading && (
									<>
										<Loading />
									</>
								)}
								{!loading && !data && (
									<>
										<NoData />
									</>
								)}
								{!loading && data && (
									<>
										<SimpleGrid columns={[1, null, 2]} spacingX={8}>
											<LabelDetail labelWidth={LABEL_WIDTH} direction="row" label="Number" titleDetail={data?.number} />
											<LabelDetail
												labelWidth={LABEL_WIDTH}
												direction="row"
												label="Created By"
												childDetail={<CompanyName company={data?.company} />}
											/>
											<LabelDetail
												labelWidth={LABEL_WIDTH}
												direction="row"
												label="Started At"
												childDetail={<LocalizedDate dateString={data?.createdAt} />}
											/>
											<LabelDetail
												labelWidth={LABEL_WIDTH}
												direction="row"
												label="Status"
												childDetail={<IntermediaryStatus status={data?.adminStatus} />}
											/>
										</SimpleGrid>
										<SimpleGrid columns={[1, null, 2]} spacingX={8}>
											<Box>
												<LabelDetail
													labelWidth={LABEL_WIDTH}
													direction="row"
													label="Branch Name(Khmer)"
													titleDetail={data?.branchName}
												/>
												<LabelDetail
													labelWidth={LABEL_WIDTH}
													direction="row"
													label="Branch Name(English)"
													titleDetail={data?.branchNameEn || 'N/A'}
												/>
												<LabelDetail
													labelWidth={LABEL_WIDTH}
													label="Branch Address"
													childDetail={
														<Address
															provinceObject={data?.provinceObject}
															districtObject={data?.districtObject}
															communeObject={data?.communeObject}
															villageObject={data?.villageObject}
															streetNumber={data?.streetNumber}
															houseNumber={data?.houseNumber}
															isGeoManual={data?.isGeoManual}
															geoManual={data?.geoManual}
														/>
													}
												/>
												<LabelDetail
													label="NBC Allowed Branch Letter"
													childDetail={
														<DownloadAndViewButton url={data?.nbcLetter?.url} size={data?.nbcLetter?.size} marginLeft={0} />
													}
												/>
											</Box>
											<Box>
												<LabelDetail
													labelWidth={LABEL_WIDTH}
													direction="row"
													label="Branch Manager Name(Khmer)"
													titleDetail={data?.managerName}
												/>
												<LabelDetail
													labelWidth={LABEL_WIDTH}
													direction="row"
													label="Branch Manager Name(English)"
													titleDetail={data?.managerNameEn}
												/>
												<LabelDetail
													labelWidth={LABEL_WIDTH}
													direction="row"
													label="Gender"
													titleDetail={t({ m: 'Male', f: 'Female' }[data?.gender])}
												/>
												{data?.idDocumentType === 'n' && (
													<>
														<LabelDetail
															labelWidth={LABEL_WIDTH}
															direction="row"
															label="National ID Card Number"
															childDetail={data?.idDocumentNumber}
														/>
														<LabelDetail
															label="National ID Card Photo"
															childDetail={
																<DownloadAndViewButton
																	url={data?.idDocument?.url}
																	size={data?.idDocument?.size}
																	marginLeft={0}
																/>
															}
														/>
													</>
												)}
												{data?.idDocumentType === 'p' && (
													<>
														<LabelDetail
															labelWidth={LABEL_WIDTH}
															direction="row"
															label="Passport Number"
															childDetail={data?.idDocumentNumber}
														/>
														<LabelDetail
															label="Passport Photo"
															childDetail={
																<DownloadAndViewButton
																	url={data?.idDocument?.url}
																	size={data?.idDocument?.size}
																	marginLeft={0}
																/>
															}
														/>
													</>
												)}
												<LabelDetail
													label="Working Contract"
													childDetail={
														<DownloadAndViewButton
															url={data?.workingContract?.url}
															size={data?.workingContract?.size}
															marginLeft={0}
														/>
													}
												/>
											</Box>
										</SimpleGrid>
										<Divider mb={2} mt={4} />
										<SimpleGrid columns={[1, null, 2]} spacingX={8}>
											<LabelDetail
												label="Decision"
												childDetail={
													<UpdateIntemediaryBranchStatus id={data?.id} status={data?.branchStatus} onCallback={(e) => setData(e)} />
												}
											/>
											{data?.branchStatus === 'rejected' && (
												<LabelDetail label="Reason of Rejection" childDetail={<Text color="red.400">{t(data?.description)}</Text>} />
											)}
											{data?.adminStatus === 'completed' && data?.completedLetter?.url && (
												<LabelDetail
													label="Approval Letter"
													childDetail={<DownloadAndViewButton url={c.baseFilePath + data?.completedLetter?.url} marginLeft={0} />}
												/>
											)}
										</SimpleGrid>
										<Divider mb={4} mt={4} />
										<Tabs variant="enclosed">
											<TabList>
												{data?.details?.map((item, index) => (
													<>
														<Tab key={`th-${index}`}>{t(item?.partnerType)}</Tab>
													</>
												))}
											</TabList>
											<TabPanels>
												{data?.details?.map((item, index) => {
													return (
														<TabPanel key={`t-${index}`} borderWidth={1} borderStyle="solid" borderColor="gray.200">
															{item?.oldCompany?.id && (
																<LabelDetail
																	label="Old Partnered Insurance Company"
																	childDetail={<CompanyName company={item?.oldCompany} />}
																/>
															)}
															<SimpleGrid columns={[1, null, 2]} w="100%" bg="white" spacingX={8}>
																<LabelDetail
																	label="Partnered Insurance Company"
																	childDetail={<CompanyName company={item?.partner} color="blue.500" />}
																/>
																<LabelDetail label="Model" childDetail={<IntermediaryModel model={item?.model} />} />
															</SimpleGrid>
															{item?.model === direct && (
																<LabelDetail
																	mt={2}
																	mb={0}
																	label="Professional Individual List"
																	childDetail={
																		<Box width="100%" mt={1}>
																			<ProfessionalIndividualList
																				id={item?.id}
																				callback={(e) =>
																					setPfListCompleted({ ...pfListCompleted, [e?.id]: e?.isCompleted })
																				}
																				type="new"
																				from="branch"
																			/>
																		</Box>
																	}
																/>
															)}
															{item?.model === reference && (
																<LabelDetail
																	mb={0}
																	mt={2}
																	label="Sale Staff List"
																	childDetail={
																		<Box w="100%" mt={1}>
																			<SaleProfessionalList
																				id={item?.id}
																				onCallback={(e) =>
																					setPfListCompleted({ ...pfListCompleted, [e?.id]: e?.isCompleted })
																				}
																				emptyLabel="No Sale Staff Selected"
																			/>
																		</Box>
																	}
																/>
															)}
														</TabPanel>
													);
												})}
											</TabPanels>
										</Tabs>
									</>
								)}
							</Box>
						</Box>
					</Center>
				</Container>
			</PrintLayout>
		</>
	);
}

export default IntermediaryPartnerRequest;
